<template>
  <section
    class="box-height differenceLastYear"
    style="height: 100%; padding: 0 15px !important"
  >
    <div style="background-color: #fff">
      <el-row>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="firstYear + '年差额'" name="first"></el-tab-pane>
          <el-tab-pane
            :label="secondYear + '年差额'"
            name="second"
          ></el-tab-pane>
          <el-tab-pane :label="threeYear + '年差额'" name="three"></el-tab-pane>
        </el-tabs>
      </el-row>
      <el-form :model="filter" ref="filter" label-width="65px">
        <el-row>
          <div>
            <el-form-item label="顾问：" prop="userIds" class="fl">
              <el-cascader
                style="width: 240px"
                clearable
                size="mini"
                filterable
                :show-all-levels="false"
                :options="options2"
                collapse-tags
                v-model="userIds"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  multiple: true,
                  checkStrictly: false,
                }"
                ref="dataRef"
                @change="getSearch"
                :filter-method="filterFun"
              ></el-cascader>
            </el-form-item>
          </div>
          <el-col :span="24">
            <el-button
              type="primary"
              size="mini"
              @click="showDig"
              style="margin-left: 15px"
              >新增</el-button
            >
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-form>
      <div class="pd40" style="background-color: #fff; padding-bottom: 40px">
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          class="tabBorder custor"
        >
          <el-table-column prop="userName" label="顾问"> </el-table-column>
          <el-table-column prop="workId" label="工号"> </el-table-column>
          <el-table-column prop="calculateModelName" label="奖金计算模型名称">
            <template #header>
              {{ filter.belongYear }}年累计差额（元）
              <el-tooltip
                class="item"
                effect="dark"
                content="之前年份未抵扣的差额由人工转移至下一年份。实际差额数据以填写为准。"
                placement="bottom"
              >
                <i
                  class="iconfont icon icon-wenhao-xiangsu"
                  style="font-size: 14px"
                ></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">{{ scope.row.difference }} </template>
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template #default="scope">
              <span @click="handleEdit(scope.row)" class="tabHref"> 编辑 </span>
              <el-divider direction="vertical"></el-divider>
              <span @click="delectRow(scope.row.id)" class="tabHref">
                删除
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </div>
      <el-drawer
        :visible.sync="dialogVisible"
        append-to-body
        :modal="false"
        custom-class="drawerClass"
        :close-on-click-modal="false"
        direction="ttb"
        :before-close="closeDig"
        destroy-on-close
      >
        <div slot="title" class="drawerTitle">新增</div>
        <div class="drawerCon">
          <addDifferenceLastYear
            ref="addDifferenceLastYear"
            @changeSaveLoading="changeSaveLoading"
            @handleCloseDrawer="closeDig"
            @loadlist="search"
            :belongYear="filter.belongYear"
          ></addDifferenceLastYear>
          <div class="drawerCon_footer fixedFoot">
            <el-button @click="closeDig" size="small">取 消</el-button>
            <el-button
              type="primary"
              :loading="saveloading"
              size="small"
              @click="save"
              style="margin-right: 15px"
              >确 定</el-button
            >
          </div>
        </div>
      </el-drawer>
      <el-drawer
        :visible.sync="drawerVisible"
        append-to-body
        :modal="false"
        custom-class="drawerClass"
        :close-on-click-modal="false"
        direction="ttb"
        :before-close="closeDig1"
        destroy-on-close
      >
        <div slot="title" class="drawerTitle">编辑</div>
        <div class="drawerCon">
          <el-table
            :data="editData"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            class="tabBorder custor pctable notableBorder"
            style="width: 100%"
          >
            <el-table-column prop="userName" label="顾问"> </el-table-column>
            <el-table-column
              prop="workId"
              label="工号"
            >
              <template #default="scope">
                <el-input
                  placeholder="请输入内容"
                  v-model="scope.row.workId"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="difference"
              :label="filter.belongYear + '年度累计差额'"
            >
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model="scope.row.difference"
                  @blur="hanledifference"
                >
                </el-input>
              </template>
            </el-table-column>
          </el-table>
          <div class="drawerCon_footer fixedFoot">
            <el-button @click="drawerVisible = false" size="small"
              >取 消</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="submit"
              style="margin-right: 15px"
              >确 定</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
  </section>
</template>
  
<script>
import {
  permissionTree, //获取顾问
  bonusAnnualDifferencePage, //分页查询年度差额
  bonusAnnualDifferenceDeleteSingle, //删除单个年度差额
  bonusAnnualDifferenceEditSingle, //编辑单个年度差额
} from "../../api/api";
import addDifferenceLastYear from "../../components/set/addDifferenceLastYear";
export default {
  components: {
    addDifferenceLastYear,
  },
  data() {
    return {
      filter: {
        userIds: [],
        belongYear: new Date().getFullYear() - 1,
        page: {
          total: 0,
          current: 1,
          size: 50,
        },
      },
      ListData: [],
      listLoading: false,
      options2: [],
      dialogVisible: false,
      saveloading: false,
      drawerVisible: false,
      editData: [],
      activeName: "first",
      firstYear: new Date().getFullYear() - 1,
      secondYear: new Date().getFullYear() - 2,
      threeYear: new Date().getFullYear() - 3,
      userIds: [],
    };
  },
  created() {
    this.getList();
    this.permissionTree(); // 获取用户的组织架构
    this.activeName = this.$route.query.activeName;
    if (this.activeName == "first") {
      this.filter.belongYear = this.firstYear;
    } else if (this.activeName == "second") {
      this.filter.belongYear = this.secondYear;
    } else {
      this.filter.belongYear = this.threeYear;
    }
  },
  methods: {
    //检查是否有抵扣记录
    getBonusAnnualDifferenceHasDeduct(id, type) {
      if (type == "delect") {
        bonusAnnualDifferenceDeleteSingle({
          id: id
        }).then((res1) => {
          if (res1.success) {
            this.$message.success("删除成功");
            this.getList();
          }
        });
      } 
      else if (type == "edit") {
        bonusAnnualDifferenceEditSingle({
          difference: this.editData[0].difference,
          id: this.editData[0].id,
          workId: this.editData[0].workId
        }).then((res1) => {
          if (res1.success) {
            this.$message.success("编辑成功");
            this.drawerVisible = false;
            this.getList();
          }
        });
      }
    },
    handleClick(tab) {
      if (tab.name == "first") {
        this.filter.belongYear = this.firstYear;
      } else if (tab.name == "second") {
        this.filter.belongYear = this.secondYear;
      } else {
        this.filter.belongYear = this.threeYear;
      }
      this.getList();
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      this.filter.userIds = this.userIds.map((i) => {
        return i[i.length - 1];
      });
      this.search();
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    // 获取用户的组织架构
    permissionTree() {
      permissionTree({
        type: "顾问",
        showDisableUser: true,
        showLeaveStatus: true,
      }).then((res) => {
        if (res.success) {
          this.options2 = res.result;
        }
      });
    },
    //搜索
    search() {
      this.filter.page.current = 1;
      this.filter.page.total = 0;
      this.getList();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.getList();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.filter.page.current = val;
      this.getList();
    },
    //获取列表数据
    getList() {
      this.listLoading = true;
      bonusAnnualDifferencePage(this.filter).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //新增
    showDig() {
      this.dialogVisible = true;
    },
    //防抖节流
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    //关闭
    closeDig() {
      this.dialogVisible = false;
      this.saveloading = false;
    },
    //保存
    save() {
      this.$refs.addDifferenceLastYear.saveInfo();
    },
    //删除
    delectRow(id) {
      this.getBonusAnnualDifferenceHasDeduct(id, "delect");
    },
    //关闭弹窗
    closeDig1() {
      this.drawerVisible = false;
    },
    //编辑
    handleEdit(row) {
      this.editData[0] = row;
      this.drawerVisible = true;
    },
    hanledifference(e) {
      const pattern = /^\d{1,9}(\.\d{1,2})?$/;
      if (!pattern.test(e.target.value)) {
        this.$message.error("最多保留两位小数");
        return;
      }
    },
    //设置表格
    submit() {
      if (!this.editData[0].difference) {
        this.$message.error("请填写差额");
        return;
      }
      if (!this.editData[0].workId) {
        this.$message.error("请填写工号");
        return;
      }
      this.getBonusAnnualDifferenceHasDeduct(this.editData[0].id, "edit");
    },
  },
};
</script>  

<style lang="scss">
.differenceLastYear {
  .el-tabs__nav-scroll {
    padding: 0 15px;
  }
}
</style>
