<template>
  <section style="border: #e5e5e5 1px solid" class="setPerformanceConfig">
    <el-form
      :model="filter"
      ref="filter"
      :v-loading="isreslover"
      :rules="rules"
    >
      <el-row style="padding: 20px 30px 15px">
        <el-radio-group v-model="isOne" @change="isOneChange">
          <el-radio label="1">方式1：系统内设置</el-radio>
          <el-radio label="2">方式2：上传本地Excel表</el-radio>
        </el-radio-group>
      </el-row>
      <div v-if="isOne == '1'">
        <el-row style="display: flex">
          <el-col :span="8" class="titrow"> </el-col>
          <el-col :span="16" class="titrow titrow1">
            <div class="isRequired">
              差额设置<span style="pointer-events: none"
                >（列表支持复制粘贴）</span
              >
            </div>
            <span style="padding: 2px 0px; cursor: pointer" @click="fullScreen">
              <i
                class="iconfont icon icon-quanping"
                style="margin-right: 8px"
              ></i
              >全屏</span
            >
          </el-col>
        </el-row>
        <el-row style="display: flex">
          <el-col
            :span="8"
            style="border-right: 1px solid rgb(229, 229, 229); margin-top: 15px"
          >
            <el-form-item
              label="顾  问："
              class="is-required"
              label-width="80px"
            >
              <div class="selectCascader">
                <el-cascader
                  style="width: 250px"
                  :filterable="true"
                  :options="options"
                  clearable
                  collapse-tags
                  :show-all-levels="false"
                  v-model="searchIdList"
                  :props="props"
                  placeholder="请选择小类"
                  ref="dataRef"
                  @change="getSearch($event)"
                  class="dinputr"
                  popper-class="selectCascaderPop"
                >
                </el-cascader>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item
              label=""
              class="is-required"
              style="padding: 15px; margin-bottom: 0"
            >
              <div id="hotTable" class="hotTable">
                <hot-table
                  ref="testHot"
                  id="hot"
                  :settings="hotSettings"
                  :key="setingNum"
                ></hot-table>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-if="isOne == '2'" style="padding: 15px">
        <el-upload
          ref="uploadResume"
          class="upload-demo"
          drag
          :headers="myHeaders"
          action
          :auto-upload="false"
          :on-change="handleChange"
          :on-success="handleSuccess"
          :file-list="fileInfoList"
          :on-remove="handleRemove"
          :limit="1"
          :on-exceed="onExceed"
          accept=".xlsx, .xls"
        >
          <i
            class="el-icon-upload"
            style="font-size: 60px; color: #999; margin: 15px 0"
          ></i>
          <div class="el-upload__text" style="line-height: 20px">
            将文件拖到此处，或
            <span class="tabHref">点击上传</span>
          </div>
          <span
            class="tabHref"
            @click.stop="downLoadM"
            style="line-height: 30px"
            >下载模板</span
          >
        </el-upload>
        <div
          style="
            background: #f7f7f7;
            font-size: 13px;
            padding: 15px;
            margin: 10px 0;
            color: #606266;
          "
        >
          <div style="margin-bottom: 8px; font-weight: bold; color: #333">
            1、操作步骤：
          </div>
          <p style="margin: 5px 0">
            请先<span class="tabHref" @click="downLoadM">下载模板</span
            >，按照模板格式填写后再上传到系统
          </p>
          <div style="margin-bottom: 8px; font-weight: bold; color: #333">
            2、表格注意事项：
          </div>
          <p style="margin: 5px 0"></p>
          <p style="margin: 5px 0">
            请删除模板中的示例项，否则会上传到系统中；
          </p>
          <p style="margin: 5px 0">请将上传的内容放在第1个sheet中；</p>
          <p style="margin: 5px 0">表头必须包含[对象名]；</p>
          <p style="margin: 5px 0">
            仅支持.xlsx文件；<span class="tabHref" @click="showMore"
              >更多注意事项></span
            >
          </p>
          <div v-if="moreShow">
            <p style="margin: 5px 0">仅支持单表头表；</p>
            <p style="margin: 5px 0">请不要加密模板文件，将导致错误；</p>
            <p style="margin: 5px 0">请不要上传带宏的文件，将导致错误；</p>
            <p style="margin: 5px 0">
              请不要过多的使用格式，如背景色和边框，将可能导致超时；
            </p>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 全屏展示表格 -->
    <el-drawer
      :visible.sync="drawerVisible"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :with-header="false"
    >
      <div
        style="margin: 15px 20px; height: calc(100% - 80px)"
        v-if="drawerVisible"
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0px 10px;
            background: none;
            border: 0;
            padding: 0;
          "
          class="titrow titrow1"
        >
          <p class="isRequired">
            差额设置<span style="pointer-events: none"
              >（列表支持复制粘贴）</span
            >
          </p>
          <span
            style="padding: 2px 0px; cursor: pointer"
            @click="closeFullScreen()"
          >
            <i
              class="iconfont icon icon-suoxiao"
              style="cursor: pointer; margin-right: 8px"
            ></i
            >缩小
          </span>
        </div>
        <hot-table
          ref="testHot1"
          id="hot"
          class="large"
          :settings="hotSettings"
        ></hot-table>
      </div>
    </el-drawer>
    <!-- 弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
    >
      <div>
        <p>系统识别结果如下，请确认：</p>
        <el-table
          :data="validateData.validateExcelResult"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          class="tabBorder custor pctable notableBorder"
          style="width: 100%"
        >
          <el-table-column prop="columnIndex" label="位置" width="80">
            <template #default="scope">
              第{{ scope.row.columnIndex }}行
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="顾问名" width="120">
          </el-table-column>
          <el-table-column prop="detail" label="系统识别情况">
            <template #default="scope">
              <span
                v-html="scope.row.detail"
                v-if="scope.row.res == '无法上传'"
                style="color: #e60012"
                >{{ scope.row.detail }}</span
              >
              <span
                v-html="scope.row.detail"
                v-if="scope.row.res == '正常上传'"
                style="color: #23a7df"
                >{{ scope.row.detail }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="res" label="预计处理结果" width="120">
            <template #default="scope">
              <span v-if="scope.row.res == '无法上传'" style="color: #e60012">{{
                scope.row.res
              }}</span>
              <span v-if="scope.row.res == '正常上传'" style="color: #23a7df">{{
                scope.row.res
              }}</span>
              <span v-if="scope.row.res == '正常上传，新增'">{{
                scope.row.res
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <span class="dialog-footer">
          <el-button size="small" @click="closeDig1">取消，放弃上传</el-button>
          <el-button size="small" type="primary" @click="saveExcelData"
            >确认，继续上传</el-button
          >
        </span>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import Cookies from "js-cookie";
import {
  bonusAnnualDifferenceDownloadTemplate, //下载模板
  bonusAnnualDifferenceNormalSet, //顾问奖金设置
  bonusAnnualDifferenceExcelSet, //顾问奖金设置表格
  permissionType,
  permissionTree,
} from "../../api/api";
import { HotTable } from "@handsontable/vue";
import "handsontable/dist/handsontable.full.css";
import imgUpload from "../../components/imgUpload";
import { ApiBaseUrl } from "../../api/http";
export default {
  props: ["belongYear"],
  components: {
    HotTable,
    imgUpload,
  },
  data() {
    const year = new Date().getFullYear().toString();
    // 校验数字格式
    const myNumeric = (value, callback) => {
      if (value) {
        if (typeof value !== "number") {
          callback(false);
        } else {
          let str = String(value);
          if (str.indexOf("e") != -1) {
            callback(false);
          } else if (
            str.split(".")[0].length > 16
            // value > 0
            //   ? str.split(".")[0].length > 10
            //   : str.split(".")[0].length > 11
          ) {
            callback(false);
          } else if (str.split(".")[1] && str.split(".")[1].length > 2) {
            callback(false);
          } else {
            callback(true);
          }
        }
      } else {
        callback(true);
      }
    };
    return {
      filter: {
        year: year,
        type: "顾问",
      },
      rules: {
        // year: [
        //   { required: true, message: "请选择目标年份", trigger: "change" },
        // ],
      },
      hotSettings: {
        data: [],
        columns: [
          {
            data: "action",
            type: "text",
          },
          {
            data: "userName",
            type: "text",
            manualColumnFreeze: true,
          },
          {
            data: "workId",
            type: "text",
          },
          {
            data: "difference",
            type: "numeric",
            validator: myNumeric,
          },
        ],
        colHeaders: ["操作", "顾问", "工号", "差额（元）"],
        colWidths: [80, 130, 130, 130],
        cells: function (row, column, prop) {
          // 动态设置一些单元格的属性
          const cellProperties = { readOnly: false, className: "" };
          const visualRowIndex = this.instance.toVisualRow(row);
          const visualColIndex = this.instance.toVisualColumn(column);
          if (visualColIndex === 0 || visualColIndex === 1) {
            cellProperties.readOnly = true;
            cellProperties.className = "forbidden";
            if (visualColIndex === 0) {
              cellProperties.className = "forbidden linkhoverStyle";
            }
          } else {
            cellProperties.className = "myCellStyle";
          }
          return cellProperties;
        },
        width: "100%",
        fixedRowsTop: 0, // 固定顶部多少行不能垂直滚动
        fixedColumnsLeft: 2, // 固定左侧多少列不能水平滚动
        stretchH: "all", // last/all/none last为延伸最后一列，all为延伸所有列，none表示默认不延伸
        autoWrapRow: true, // 自动隐藏行
        autoColumnSize: true, // 自适应列大小
        height: 550, // 高度
        manualRowResize: true, // 允许拖动改变行的高度
        manualColumnResize: true, // 允许拖动改变列的宽度
        rowHeaders: false, // 当值为true时显示行头，当值为数组时，行头为数组的值
        manualRowMove: false, // 为true时，行可拖拽至指定行
        manualColumnMove: false, // 为true时，列可拖拽至指定列
        contextMenu: false, // 右键菜单展示
        filters: false, // 过滤
        dropdownMenu: false, // 下拉菜单
        licenseKey: "non-commercial-and-evaluation", // 非商业用途声明
      },
      drawerVisible: false,
      dialogVisible: false,
      diglist: [],
      isOne: "1",
      fileInfoList: [],
      moreShow: false,
      isreslover: false,
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      listLoading: false,
      dialogVisible1: false,
      validateData: [],
      optionsList: [],
      options: [],
      searchIdList: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        disabled: "disabled",
        // checkStrictly: true
      },
      setingNum: 0,
    };
  },
  created() {
    console.log(this.hotSettings.data);
    this.init();
    this.permissionType();
    this.permissionTree("顾问", true);
  },
  watch: {
    // 监听表格对应数组，发生变化就判断每一行是否有action，如果没有就删除这一行数据
    "hotSettings.data": {
      handler(newVal) {
        let reload = false;
        for (let i = 0; i < newVal.length; i++) {
          if (!newVal[i].action) {
            newVal.splice(i, 1);
            reload = true;
          } else {
            this.$refs["testHot"].hotInstance.loadData(newVal);
          }
        }
        if (reload) {
          if (this.$refs["testHot1"]) {
            this.$refs["testHot1"].hotInstance.loadData(newVal);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    isOneChange(val) {
      if (val == "1") {
        this.init();
        this.permissionType();
        this.permissionTree("顾问", true);
      }
    },
    init() {
      // this.getTree(); // 获取设置信息
      // 为表格注册各种事件
      this.$nextTick(() => {
        // 为两个表格中删除单元格注册点击事件
        this.$refs["testHot"].hotInstance.addHook(
          "afterOnCellMouseDown",
          (event, coords, td) => {
            this.callBackMD(event, coords, td, "testHot");
          }
        );
        // 为表格注册验证器被调用前的事件
        this.$refs["testHot"].hotInstance.addHook(
          "beforeValidate",
          (value, row, prop, source) => {
            this.callBackBV(value, row, prop, source, "testHot");
          }
        );
      });
    },
    // 删除行
    deleteRow(ref, index) {
      let searchIdList = this.searchIdList;
      for (var i = 0; i < searchIdList.length; i++) {
        if (
          this.hotSettings.data[index].userId ==
          searchIdList[i][searchIdList[i].length - 1]
        ) {
          searchIdList.splice(i, 1);
          this.sourceIds.splice(i, 1);
        }
      }
      this.cencelDisable(this.hotSettings.data[index].userId, this.options);
      this.searchIdList = searchIdList;
      if (this.$refs[ref]) {
        this.$refs[ref].hotInstance.alter("remove_row", index, 1);
        this.hotSettings.data.slice(index, 1);
      }
    },
    cencelDisable(id, data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].id == id && data[i].type == "顾问") {
          data[i].disabled = false;
        }
        if (data[i].children != null) {
          this.cencelDisable(id, data[i].children);
        }
      }
    },
    // 注册handsontable单击事件的回调函数，第一列单元格点击后询问是否删除，确认删除
    callBackMD(event, coords, td, ref) {
      var row = coords.row;
      var col = coords.col;
      if (col == 0 && row != -1) {
        const rowOfGoal =
          this.$refs["testHot"].hotInstance.getSourceDataAtRow(row); // 获取对应行的所有数据
        this.$confirm("确定删除？", "提示", {
          customClass: "altCls",
          type: "warning",
        })
          .then(() => {
            this.deleteRow("testHot", row);
            let list = [];
            list[0] = rowOfGoal.objectId;
            // this.disableSelectdOptions(
            //   list,
            //   this.options,
            //   rowOfGoal.type,
            //   false
            // );
            if (this.$refs[ref]) {
              this.$refs[ref].hotInstance.loadData(this.hotSettings.data);
            }
          })
          .catch(() => {});
      }
    },
    // 注册handsontable验证器被调用前的事件的回调函数
    callBackBV(value, row, prop, source, ref) {
      if (value && typeof value == "string" && value.indexOf(",") != -1) {
        let val = Number(value.replace(/,/g, ""));
        if (this.$refs[ref]) {
          this.$nextTick(() => {
            this.$refs[ref].hotInstance.setDataAtRowProp(
              row,
              prop,
              val,
              source
            );
          });
        }
      }
      let str = String(value);
      if (
        str.indexOf("e") == -1 &&
        str.split(".")[1] &&
        str.split(".")[1].length > 5
      ) {
        let newStr = [
          str.split(".")[0],
          str.split(".")[1].substring(0, 5),
        ].join(".");
        let val = Number(newStr);
        if (this.$refs[ref]) {
          this.$nextTick(() => {
            this.$refs[ref].hotInstance.setDataAtRowProp(
              row,
              prop,
              val,
              source
            );
          });
        }
      }
    },
    //全屏
    fullScreen() {
      this.drawerVisible = true;
      this.$nextTick(() => {
        // 注册点击事件
        this.$refs["testHot1"].hotInstance.addHook(
          "afterOnCellMouseDown",
          (event, coords, td) => {
            this.callBackMD(event, coords, td, "testHot1");
          }
        );
        // 为表格注册验证器被调用前的事件
        this.$refs["testHot1"].hotInstance.addHook(
          "beforeValidate",
          (value, row, prop, source) => {
            this.callBackBV(value, row, prop, source, "testHot1");
          }
        );
        this.$refs["testHot1"].hotInstance.loadData(this.hotSettings.data); // 重新载入表格
        this.$refs["testHot1"].hotInstance.validateCells(); // 校验表格
      });
    },
    // 保存全屏修改的表格
    closeFullScreen() {
      this.$nextTick(() => {
        this.$refs["testHot"].hotInstance.loadData(this.hotSettings.data);
        this.$refs["testHot"].hotInstance.validateCells();
      });
      this.drawerVisible = false;
    },
    // 保存
    saveInfo() {
      // 判断是否已选择顾问
      if (this.hotSettings.data.length == 0) {
        this.$message.error("请先选择顾问");
        return;
      }
      for (var i = 0; i < this.hotSettings.data.length; i++) {
        if (
          !this.hotSettings.data[i].difference ||
          this.hotSettings.data[i].difference == null
        ) {
          this.$message.error("请填写差额");
          return;
        }
      }
      // 保存前校验表格内容是否符合格式
      this.$refs["testHot"].hotInstance.validateCells((valid) => {
        if (valid) {
          this.$emit("changeSaveLoading", true);
          bonusAnnualDifferenceNormalSet({
            continueFromExcel: false,
            differenceList: this.hotSettings.data,
          }).then((res) => {
            this.$emit("changeSaveLoading", false);
            if (res.success) {
              this.$message.success("保存成功");
              this.$emit("handleCloseDrawer", true);
              this.$emit("loadlist");
            } else {
              let div =
                "<div style='text-align:center;color:red'>" +
                res.result +
                "</div>";
              this.$confirm(div, "提示", {
                showCancelButton: false,
                showConfirmButton: false,
                customClass: "altCls",
                dangerouslyUseHTMLString: true,
              }).then(() => {});
            }
          });
        } else {
          this.$message.error("差额设置表格内容格式错误");
        }
      });
    },
    getFileInfoList: function (fileInfoList) {
      this.fileInfoList = fileInfoList;
    },
    showMore() {
      this.moreShow = true;
    },
    downLoadM() {
      bonusAnnualDifferenceDownloadTemplate().then((res) => {
        if (res.success) {
          location.href = ApiBaseUrl + res.result;
        }
      });
    },
    handleChange(file, fileList) {
      console.log(file, fileList);
      const isLtM = file.size / 1024 / 1024 < 1;
      let fileType = file.name.split(".")[file.name.split(".").length - 1];
      if (fileType != "xlsx" && fileType != "xls") {
        this.$message.error("上传的文件必须是.xlsx或.xls格式");
        this.fileInfoList = [];
        return false;
      }
      if (!isLtM) {
        this.$message.error("上传文件大小不能超过 1MB!");
        this.fileInfoList = [];
        return false;
      }
      this.fileInfoList = [];
      fileList.forEach((i) => {
        this.fileInfoList.push(i.raw);
      });
      let body = new FormData();
      this.fileInfoList.forEach((i) => {
        body.append("excelFile", i);
      });
      body.append("belongYear", this.belongYear);
      return new Promise((resolve) => {
        this.isreslover = true;
        bonusAnnualDifferenceExcelSet(body).then((res) => {
          if (res.success) {
            this.isreslover = false;
            this.dialogVisible1 = true;
            this.validateData = res.result;
          }
        });
      });
    },
    handleSuccess: function (res, file, fileList) {},
    handleRemove(file, fileList) {
      this.fileInfoList = [];
      fileList.forEach((i) => {
        this.fileInfoList.push(i.raw);
      });
    },
    onExceed() {
      this.$message.error("一次仅可上传单个文件");
    },
    //excel保存
    saveExcelData() {
      bonusAnnualDifferenceNormalSet({
        continueFromExcel: true,
        differenceList: this.validateData.correctData,
      }).then((res) => {
        this.$emit("changeSaveLoading", false);
        if (res.success) {
          this.$message.success("操作成功");
          this.$emit("handleCloseDrawer", true);
          this.$emit("loadlist");
        }
      });
    },
    closeDig1() {
      this.dialogVisible1 = false;
      this.$emit("handleCloseDrawer", true);
      this.$emit("loadlist");
    },
    // 查询维度
    // 大类
    permissionType() {
      permissionType().then((res) => {
        if (res.success) {
          this.optionsList = res.result;
        }
      });
    },
    // 小类
    permissionTree(searchType, isfirst) {
      permissionTree({
        type: searchType,
        showDisableUser: true,
        showLeaveStatus: true,
      }).then((res) => {
        if (res.success) {
          this.options = res.result;
          let data = [];
          this.hotSettings.data.map((item) => {
            data.push(item.objectId);
          });
          // this.disableSelectdOptions(
          //   data,
          //   this.options,
          //   this.filter.type,
          //   true
          // );
        }
      });
    },
    changeOption(val) {
      if (val) {
        this.permissionTree(val);
      }
    },
    getSearch(val) {
      console.log(val);
      this.dataProcessing("dataRef", this.filter.type, this.options);
    },
    dataProcessing(val, type, data) {
      // let ids = JSON.stringify(this.hotSettings.data);
      // ids = JSON.parse(ids);
      const _nodeId = this.$refs[val].getCheckedNodes();
      let namelist = this.disableSelectdOptions(_nodeId, data, type, true, []);
      this.sourceIds = this.searchIdList.map((i) => {
        return i[i.length - 1];
      });

      for (var i = 0; i < this.sourceIds.length; i++) {
        let item = {
          id: null,
          action: "删除",
          userName: null,
          difference: null,
          belongYear: null,
          userId: null,
        };
        item.belongYear = this.belongYear;
        item.userId = this.sourceIds[i];
        item.userName = namelist[i].name;
        this.hotSettings.data.push(item);
      }
      // console.log(this.hotSettings.data);
      // for (var i = 0; i < ids.length; i++) {
      //   this.sourceIds = this.sourceIds.filter(function (val) {
      //     return val !== ids[i].objectId;
      //   });
      // }
      //  let item = {
      //   action: "删除",
      //   userName: null,
      //   calculateModelName: null,
      //   fixedSalary: null,
      //   startDate: null,
      //   endDate: null,
      //   userId:null
      // };
      // let data1=this.hotSettings.data
      // data1.push(item)
      // this.$set(this.hotSettings, "data", data1);
      // this.hotSettings.data =data1;
      // console.log(this.sourceIds,this.hotSettings.data)
      // let item = {
      //   action: "删除",
      //   userName: null,
      //   calculateModelName: null,
      //   fixedSalary: null,
      //   startDate: null,
      //   endDate: null,
      //   userId:null
      // };
      // this.hotSettings.data.unshift(item);
      // console.log(this.hotSettings.data)
      // this.$nextTick(() => {
      //   this.$refs["testHot"].hotInstance.loadData(this.hotSettings.data);
      //   this.$refs["testHot"].hotInstance.validateCells();
      // });
      // this.setingNum++;
      // console.log(namelist);
    },
    disableSelectdOptions(key, childrenData, type, blon, list) {
      for (var j = 0; j < childrenData.length; j++) {
        for (var i = 0; i < key.length; i++) {
          if (key[i].data) {
            if (
              childrenData[j].id == key[i].data.id &&
              childrenData[j].type == type
            ) {
              childrenData[j].disabled = blon;
              list.push(childrenData[j]);
              // childrenData[j].disabled = blon;
            }
          } else {
            if (childrenData[j].id == key[i] && childrenData[j].type == type) {
              childrenData[j].disabled = blon;
              list.push(childrenData[j]);
            }
          }
        }
        if (childrenData[j].children != null) {
          this.disableSelectdOptions(
            key,
            childrenData[j].children,
            type,
            blon,
            list
          );
        }
      }
      // this.options = childrenData;
      return list;
    },
  },
};
</script>
 
 <style lang="scss">
.hotTable {
  // margin-bottom: 25px;
  #hot.large {
    height: 100% !important;
    width: 100% !important;
    overflow: hidden;
    .ht_master.handsontable.innerBorderLeft {
      height: 100% !important;
      .wtHolder {
        max-height: 100% !important;
      }
    }
  }
  #hot.smallScreen {
    .ht_master.handsontable.innerBorderLeft .wtHolder {
      max-height: 230px;
    }
    .ht_clone_left.handsontable .wtHolder {
      height: 213px !important;
    }
  }
  #hot {
    height: auto !important;
    width: 100% !important;
    overflow: hidden;
    .ht_master.handsontable.innerBorderLeft {
      .wtHolder {
        overflow: auto;
        max-height: 600px;
        height: auto !important;
        width: 100% !important;
      }
    }
    .ht_clone_top.handsontable {
      height: auto !important;
      .wtHolder {
        height: auto !important;
      }
    }
    .ht_clone_left.handsontable {
      z-index: 1 !important;
      .wtHolder {
        overflow: hidden;
        height: auto;
      }
    }
    .handsontableInputHolder.ht_clone_master {
      textarea.handsontableInput {
        box-sizing: content-box;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
      }
    }
    th {
      background-color: #f7f7f7;
      color: #626266;
      visibility: visible;
    }
    th,
    td {
      border-color: #ededed;
    }
  }
}
.large {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  .ht_master.handsontable.innerBorderLeft {
    height: 100% !important;
    .wtHolder {
      max-height: 100% !important;
    }
  }
}
.forbidden {
  background-color: #f7f7f7;
  vertical-align: middle;
  color: #626266;
  font-size: 13px;
  padding: 10px 15px !important ;
  // text-align: left !important;
  height: auto !important;
}
.linkhoverStyle {
  color: #397cf6 !important;
  text-align: center;
  vertical-align: middle;
}
.myCellStyle {
  text-align: left !important;
  vertical-align: middle;
  color: #626266;
}
.titrow {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  background: #f0f0f0;
  border: 1px #e5e5e5 solid;
  line-height: 23px;
  padding: 5px 15px;
  font-weight: bold;
  color: #333;
}
.titrow span {
  color: #606266;
  font-size: 13px;
  font-weight: 400;
  margin-left: 5px;
  // &:hover {
  //   color: #e60012;
  // }
}
.titrow1 span {
  color: #606266;
  font-size: 13px;
  font-weight: 400;
  margin-left: 5px;
  &:hover {
    color: #e60012;
  }
}
.setPerformanceConfig {
  .iconshangchuan {
    display: inline-block;
    font-size: 60px;
    color: #c2c2c2;
    margin-top: 40px;
  }
  .el-upload__text {
    line-height: 1;
    color: #666;
  }
  .el-upload {
    width: 100%;
    .el-upload-dragger {
      height: 145px;
      width: 100%;
      border-radius: 0;
    }
  }
  .selectCascader {
    .el-cascader__tags .el-tag .el-icon-close {
      display: none;
      background-color: #f4f4f5 !important;
    }
    .el-tag .el-icon-close::before {
      display: none;
    }
  }
}
.selectCascaderPop {
  .is-disabled {
    color: #606266 !important;
    display: revert !important;
  }
  .el-checkbox__input.is-disabled .el-checkbox__inner::after {
    border-color: #c0c4cc !important;
    transform: rotate(45deg) scaleY(1) !important;
  }
}
</style>
<style lang="scss" scoped>
.hotTable {
  .drawerClass .drawerCon .fixedFoot {
    z-index: 1000;
  }
}
.isRequired:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
</style>